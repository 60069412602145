<template>
  <div>
    <b-row>
        <b-col>
          <h1>Attachments</h1>
        </b-col>
        <!-- <b-form-checkbox v-model="livestream.speakers.active" name="active" class="content-switch" switch><b>Active</b></b-form-checkbox> -->
        <b-col cols="auto">
          <b-button
            class="mb-5 add-button"
            @click="addAttachment()"
          >
            Add
          </b-button>
        </b-col>
      </b-row>
      <b-overlay :show="isLoading">
        <vuetable
          ref="attachmenttable"
          :api-mode="false"
          :fields="[
            'active',
            'id',
            'title.nl',
            'type.nl',
            'actions'
          ]"
          :data="attachments"
        >
          <template slot="active" scope="props">
            <b-form-checkbox v-model="attachments.data[props.rowIndex].active" name="active" class="content-switch" disabled></b-form-checkbox>
          </template>
          <template slot="actions" scope="props">
            <font-awesome-icon
              icon="edit"
              class="action-icon"
              data-toggle="tooltip" 
              data-placement="top" 
              title="Edit item"
              @click="editAttachment(props.rowIndex)"
              ref="btnEdit"
              ></font-awesome-icon>
            <font-awesome-icon
              icon="trash"
              class="ml-2 action-icon"
              data-toggle="tooltip" 
              data-placement="top" 
              title="Remove item"
              @click="deleteAttachment(props.rowData, props.rowIndex)"
              ></font-awesome-icon>
          </template>
        </vuetable>
      </b-overlay>
  </div>
</template>
<script>
import Vuetable from "vuetable-2";

export default {
  components: {
    Vuetable
  },
  data() {
    return {
      isLoading: false,
    };
  },
  async created() {
    this.doFetchAll()
  },
  methods: {
    async doFetchAll() {
      this.isLoading = true;
      await this.$store.dispatch("Attachments/fetchAll");
      this.isLoading = false;
    },
    addAttachment() {
      this.$router.push({path: 'attachments-detail'})
    },
    editAttachment(index) {
      this.$router.push({path: 'attachments-detail', query: {id: this.attachments.data[index].id}})
    },
    async deleteAttachment(attachment, index) {
      this.isLoading = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove ${attachment.id}?`, 
        {
          title: "Confirm delete",
          okVariant: "danger",
          centered: true,
        }
      );
      if (isConfirmed) {
        this.attachments.splice(index, 1)
        await this.$store.dispatch("Attachments/delete", attachment);
      }
      this.isLoading = false;
    }
  },
  computed: {
    attachments() {
      return this.$store.state.Attachments.attachments;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}
</style>
